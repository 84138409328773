<template>
 
<div class="policies-table">
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title d-flex"><i class="ri-admin-line mr-2 text-blue"></i>Admin</h4>
    </template>
  </iq-card>
  <iq-card>
    <template v-slot:body class="p-0">
      <b-row>
        <b-col sm="3">
          <tab-nav :pills="true" :vertical="true" class="text-center" id="v-pills-tab">
            <tab-nav-items :active="true" id="v-pills-dash-tab" href="#user-list" ariaControls="v-pills-home" role="tab" :ariaSelected="true" title="Dashboard" />
            <tab-nav-items :active="false" id="v-pills-user-tab" href="#v-pills-user" ariaControls="v-pills-profile" role="tab" :ariaSelected="false" title="Users" />
            <tab-nav-items :active="false" id="v-pills-post-tab" href="#v-pills-post" ariaControls="v-pills-profile" role="tab" :ariaSelected="false" title="Post" />
            <tab-nav-items :active="false" id="v-pills-event-tab" href="#v-pills-event" ariaControls="v-pills-profile" role="tab" :ariaSelected="false" title="News &Events" />
             <tab-nav-items :active="false" id="v-pills-event-tab" href="#v-pills-policies" ariaControls="v-pills-profile" role="tab" :ariaSelected="false" title="Policies" />
          </tab-nav>
        </b-col>
        <b-col sm="9">
          <tab-content id="v-pills-tabContent" class="mt-0">
            <tab-content-item :active="true" id="user-list" aria-labelled-by="v-pills-dash-tab">
              
              <iq-card>
                <template v-slot:body class="p-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb iq-bg-primary">
                      <li class="breadcrumb-item"><a href="#"><i class="ri-user-line mr-1 float-left"></i>Dashboard</a></li>
                    </ol>
                  </nav>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="iq-card position-relative inner-page-bg bg-primary" style="height: 150px;">
                        <div class="inner-page-title">
                          <h3 class="text-white">{{users.length}}</h3>
                          <p class="text-white">Users</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="iq-card position-relative inner-page-bg bg-primary" style="height: 150px;">
                        <div class="inner-page-title">
                          <h3 class="text-white">{{posts.length}}</h3>
                          <p class="text-white">Posts</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="iq-card position-relative inner-page-bg bg-primary" style="height: 150px;">
                        <div class="inner-page-title">
                          <h3 class="text-white">{{events.length}}</h3>
                          <p class="text-white">Events</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </template>
              </iq-card>
            
              
            </tab-content-item>
            <tab-content-item :active="false" id="v-pills-user" aria-labelled-by="v-pills-user-tab">
              <nav aria-label="breadcrumb">
                    <ol class="breadcrumb iq-bg-primary">
                      <li class="breadcrumb-item"><a href="#"><i class="ri-user-line mr-1 float-left"></i>Users</a></li>
                    </ol>
                  </nav>
              <div class="row justify-content-between mb-3">
                <div class="col-sm-12 col-md-6">
                  <div id="user_list_datatable_info" class="dataTables_filter">
                    <form class="mr-3 position-relative">
                      <div class="form-group mb-0">
                        <!-- <input type="search" class="form-control" id="exampleInputSearch" placeholder="Search"> -->
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>
                      </div>
                    </form>
                  </div>
                  <p class="mt-3">Search by Name, Email</p>
                </div>
                <div class="col-sm-12 col-md-6 text-right">
                  <!-- <b-button variant="primary" @click="add">Add New</b-button> -->
                  
                   <b-button variant="primary"  v-b-modal.modal1>Add New</b-button>
                </div>
                <b-modal id="modal1" title="Add User" hide-footer>
                  <form>
                      <!-- <span v-if="userError"><b-alert variant="danger" show></b-alert></span> -->
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Name" v-model="user.name">
                         <span v-if="userError.name" class="text-danger">{{userError.name}}</span>
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="email" class="form-control" id="exampleInputSearch" placeholder="Email" v-model="user.email" >
                        <span v-if="userError.email" class="text-danger">{{userError.email}}</span>
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Designation" v-model="user.designation">
                         <span v-if="userError.designation" class="text-danger">{{userError.designation}}</span>
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="password" class="form-control" id="exampleInputSearch" placeholder="Password" v-model="user.password">
                         <span v-if="userError.password" class="text-danger">{{userError.password}}</span>
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="password" class="form-control" id="exampleInputSearch" placeholder="Confirm Password" v-model="user.password_confirmation">
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                      <b-form-select v-model="user.parentId" title="Select Parent User" >
                        <b-form-select-option :value="0" disabled>Select parent user</b-form-select-option>
                        <b-form-select-option v-for="user in users" :key="user.id" :value="user.id">{{user.name}}</b-form-select-option>
                      </b-form-select>
                      </div>
                    <b-button class="mt-3"  variant="primary" block @click="add">Submit</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
                  </form>
                </b-modal>
              </div>
              <b-table striped hover :per-page="perPage" :filter="filter" @filtered="onFiltered" v-if = "renderComponent"
      :current-page="currentPage" id="my-table" :items="users" :fields="columns">
                <template v-slot:cell(name)="data"> <span class="mr-2 align-self-center text-blue" size="sm"> <i class="las la-user"></i></span> <span v-if="!data.item.editable">{{ data.item.name }}</span>
                  <input type="text" v-model="data.item.name" v-else class="form-control">
                </template>
                <template v-slot:cell(username)="data"> <span v-if="!data.item.editable">{{ data.item.username }}</span>
                  <input type="text" v-model="data.item.username" v-else class="form-control">
                </template>
                <template v-slot:cell(activated)="data"> <span v-if="!data.item.editable">{{ data.item.activated }}</span>
                  <input type="text" v-model="data.item.activated" v-else class="form-control">
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item,data.index,$event.target)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                </template>


      




              </b-table>
              <b-modal id="delete-modal" title="Delete User"  hide-footer>
                <p>Are you sure to delete this user ? </p>
                   <b-button class="mt-3"  variant="primary" block @click="deleteUser">Delete</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('delete-modal')">Close</b-button>
                </b-modal>
              <b-modal :id="infoModal.id" title="Edit User" @hide="resetInfoModal" hide-footer>
                 <form>
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Name" v-model="infoModal.name">
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="email" class="form-control" id="exampleInputSearch" placeholder="Email" v-model="infoModal.email" >
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Designation" v-model="infoModal.designation">
                      </div>
                      <br/>
                      <div class="form-group mb-0">
                      <b-form-select v-model="infoModal.parentId" title="Select Parent User" >
                        <b-form-select-option :value="0" disabled>Select parent user</b-form-select-option>
                        <b-form-select-option v-for="user in users" :key="user.id" :value="user.id">{{user.name}}</b-form-select-option>
                      </b-form-select>
                      </div>
                      <b-button class="mt-3"  variant="primary" block @click="editUser">Update</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide(infoModal.id)">Close</b-button>
                      
                  </form>
              </b-modal>
              <div class="mt-3 mt-5">
                  <b-pagination v-model="currentPage" :value="1" :total-rows="users.length" :per-page="perPage" aria-controls="my-table" />
              </div>
            </tab-content-item>
            <tab-content-item :active="false" id="v-pills-post" aria-labelled-by="v-pills-post-tab">
              <iq-card>
                <template v-slot:body class="p-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb iq-bg-primary">
                      <li class="breadcrumb-item"><a href="#"><i class="ri-article-fill mr-2"></i>Post</a></li>
                    </ol>
                  </nav>
                  <div class="col-sm-12 col-md-6">
                    <div id="user_list_datatable_info" class="dataTables_filter">
                      <form class="mr-3 position-relative">
                        <div class="form-group mb-0">
                         <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>
                        </div>
                      </form>
                    </div>
                    <p class="mt-3">Search by Name, Post Type</p>
                  </div>



                  <b-table striped hover :per-page="perPage" :filter="filter" @filtered="onFiltered" v-if = "renderComponent"
      :current-page="currentPage" id="my-table" :items="posts" :fields="postcolumns">
                <template v-slot:cell(name)="data"> <span class="mr-2 align-self-center text-blue" size="sm"> <i class="las la-user"></i></span> <span v-if="!data.item.editable">{{ data.item.name }}</span>
                  <input type="text" v-model="data.item.name" v-else class="form-control">
                </template>
                <template v-slot:cell(username)="data"> <span v-if="!data.item.editable">{{ data.item.name }}</span>
                  <input type="text" v-model="data.item.name" v-else class="form-control">
                </template>
                <template v-slot:cell(posttype)="data"> <span v-if="!data.item.editable">{{ data.item.post_type }}</span>
                  <input type="text" v-model="data.item.post_type" v-else class="form-control">
                </template>
                <template v-slot:cell(activated)="data"> <span v-if="!data.item.editable">{{ data.item.activated }}</span>
                  <input type="text" v-model="data.item.activated" v-else class="form-control">
                </template>
                
                <template v-slot:cell(action)="data">
                  <b-button variant=" iq-bg-danger" size="sm" @click="removePost(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                   <b-button variant="iq-bg-success mr-1" size="sm" @click="viewpost(data.item,data.index,$event.target)">view</b-button>
                </template>
              </b-table>
              <b-modal id="delete-post-modal" title="Delete Post"  hide-footer>
                <p>Are you sure you want to delete this post?</p>
                   <b-button class="mt-3"  variant="primary" block @click="deletePost">Delete</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('delete-post-modal')">Close</b-button>
                </b-modal>
              <b-modal :id="postModal.id" title="Post"  hide-footer>
                <p>{{postModal.caption}}</p>
                <div v-for='(image,index) in postModal.images' :key='index'>
                  <img :src="image" width='300' />
                </div>
              </b-modal>
                  <div class="mt-3 mt-5">
                    <b-pagination v-model="currentPage" :value="1" :total-rows="posts.length" :per-page="perPage" aria-controls="my-table" />
                  </div>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="v-pills-event" aria-labelled-by="v-pills-event-tab">
            <iq-card>
                <template v-slot:body class="p-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb iq-bg-primary">
                      <li class="breadcrumb-item"><a href="#"><i class="ri-calendar-event-fill mr-2"></i>News & Events</a></li>
                    </ol>
                  </nav>
                  <div class="col-sm-12 col-md-6">
                    <div id="user_list_datatable_info" class="dataTables_filter">
                      <form class="mr-3 position-relative">
                        <div class="form-group mb-0">
                           <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>
                        </div>
                        
                      </form>
                    </div>
                    <p class="mt-3">Search by Name, Event Title</p>
                  </div>
                   <!-- <div class="col-sm-12 col-md-6 text-right"> -->
                  <!-- <b-button variant="primary" @click="add">Add New</b-button> -->
                  
                   <b-button variant="primary"  v-b-modal.modal2>Add New</b-button>
                <!-- </div> -->

                 <b-modal id="modal2" title="Add Event or News" hide-footer  @hide="resetpostModal">
                  <form>
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Title" v-model="event.caption">
                      </div>
                      <!-- <br/> -->
                       <!-- <div class="form-group mb-0">
                        <input type="email" class="form-control" id="exampleInputSearch" placeholder="Description" >
                      </div> -->
                      <br/>
                       <div class="form-group col-sm-12">
                        <label for="comment">Description</label>
                        <ckeditor value="Enter here" v-model="event.description" ></ckeditor>
                      </div>
                     <div class="form-group mb-0">
                       <label for="comment">Cover Image</label>
                      <b-form-file
                        v-model="event.image"
                        :state="Boolean(event.image)" multiple
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..." 
                      ></b-form-file>
                      </div>
                      <br/>
                       <div class="form-group mb-0">
                      <b-form-select v-model="event.type" title="Select Parent User" >
                        <b-form-select-option value="event">Event</b-form-select-option>
                        <b-form-select-option value="news">News</b-form-select-option>
                      </b-form-select>
                      </div>
                    <b-button class="mt-3"  variant="primary" block @click="addEvent">Submit</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close</b-button>
                  </form>
                </b-modal>



                 <b-table striped hover :per-page="perPage" :filter="filter" @filtered="onFiltered" v-if = "renderComponent"
      :current-page="currentPage" id="my-table" :items="events" :fields="eventscolumns">
                <template v-slot:cell(name)="data"> <span class="mr-2 align-self-center text-blue" size="sm"> <i class="las la-user"></i></span> <span v-if="!data.item.editable">{{ data.item.name }}</span>
                  <input type="text" v-model="data.item.name" v-else class="form-control">
                </template>
                <template v-slot:cell(title)="data"> <span v-if="!data.item.editable">{{ data.item.caption }}</span>
                  <input type="text" v-model="data.item.caption" v-else class="form-control">
                </template>
                <template v-slot:cell(type)="data"> <span v-if="!data.item.editable">{{ data.item.type }}</span>
                  <input type="text" v-model="data.item.type" v-else class="form-control">
                </template>
                <template v-slot:cell(time)="data"> <span v-if="!data.item.editable">{{ data.item.time }}</span>
                  <input type="text" v-model="data.item.time" v-else class="form-control">
                </template>
                
                <template v-slot:cell(action)="data">
                   <b-button variant=" iq-bg-success mr-1" size="sm" @click="editEvent(data.item,data.index,$event.target)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="removeEvent(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  
                </template>


      




              </b-table>
              <b-modal id="delete-event-modal" title="Delete Event"  hide-footer>
                <p>Are you sure to delete this Event ? </p>
                   <b-button class="mt-3"  variant="primary" block @click="deleteEvent">Delete</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('delete-event-modal')">Close</b-button>
                </b-modal>
              <b-modal :id="eventModal.id" title="Edit Event or News" hide-footer>
                 <form>
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Name" v-model="eventModal.caption">
                      </div>
                      <!-- <br/>
                       <div class="form-group mb-0">
                        <input type="email" class="form-control" id="exampleInputSearch" placeholder="Email" v-model="eventModal.description" >
                      </div> -->
                      <br/>
                       <div class="form-group col-sm-12">
                        <label for="comment">Description</label>
                        <ckeditor value="Enter here" v-model="eventModal.description" ></ckeditor>
                      </div>
                      <!-- <div v-for='(img,index) in eventModal.image' :key='index' >
                          <img :src='img'/>
                      </div>
                     <div class="form-group mb-0">
                       <label for="comment">Cover Image</label>
                      <b-form-file
                        v-model="eventModal.image"
                        :state="Boolean(eventModal.image)" multiple
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..." 
                      ></b-form-file>
                      </div> -->
                      <br/>
                      <div class="form-group mb-0">
                      <b-form-select v-model="eventModal.type" title="Select Parent User" >
                        <b-form-select-option value="event">Event</b-form-select-option>
                        <b-form-select-option value="news">News</b-form-select-option>
                      </b-form-select>
                      </div>
                      <b-button class="mt-3"  variant="primary" block @click="updateEvent">Update</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide(eventModal.id)">Close</b-button>
                      
                  </form>
              </b-modal>
                   <div class="mt-3 mt-5">
                <b-pagination v-model="currentPage" :value="1" :total-rows="events.length" :per-page="perPage" aria-controls="my-table" />
              </div>
                </template>
              </iq-card>  
            </tab-content-item>




             <tab-content-item :active="false" id="v-pills-policies" aria-labelled-by="v-pills-event-tab">
            <iq-card>
                <template v-slot:body class="p-0">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb iq-bg-primary">
                      <li class="breadcrumb-item"><a href="#"><i class="ri-calendar-event-fill mr-2"></i>Policies</a></li>
                    </ol>
                  </nav>
                  <div class="col-sm-12 col-md-6">
                    <div id="user_list_datatable_info" class="dataTables_filter">
                      <form class="mr-3 position-relative">
                        <div class="form-group mb-0">
                           <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>
                        </div>
                        
                      </form>
                    </div>
                    <p class="mt-3">Search by Name, Policy Title</p>
                  </div>
                   <!-- <div class="col-sm-12 col-md-6 text-right"> -->
                  <!-- <b-button variant="primary" @click="add">Add New</b-button> -->
                  
                   <b-button variant="primary"  v-b-modal.modal3>Add New</b-button>
                <!-- </div> -->

                 <b-modal id="modal3" title="Add Policy" hide-footer  @hide="resetpolicyModal">
                  <form>
                    <div class="form-group mb-0">
                    <b-form-file
                      v-model="policy.pdfFile"
                      :state="Boolean(policy.pdfFile)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..." 
                    ></b-form-file>
                    </div>
                    <br/>
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Author" v-model="policy.author">
                      </div>
                     
                      <br/>
                       <div class="form-group mb-0">
                      <b-form-select v-model="policy.type" title="Select Parent User" >
                        <b-form-select-option value="hr">Hr</b-form-select-option>
                        <b-form-select-option value="financial">Financial</b-form-select-option>
                        <b-form-select-option value="tech">Tech</b-form-select-option>
                        <b-form-select-option value="team">Team Structure</b-form-select-option>
                      </b-form-select>
                      </div>
                    <b-button class="mt-3"  variant="primary" block @click="addPolicy">Submit</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('modal3')">Close</b-button>
                  </form>
                </b-modal>



                 <b-table striped hover :per-page="perPage" :filter="filter" @filtered="onFiltered" v-if = "renderComponent"
      :current-page="currentPage" id="my-table" :items="policies" :fields="policycolumns">
                <template v-slot:cell(name)="data"> <span class="mr-2 align-self-center text-blue" size="sm"> <i class="las la-user"></i></span> <span v-if="!data.item.editable">{{ data.item.name }}</span>
                  <input type="text" v-model="data.item.name" v-else class="form-control">
                </template>
                <template v-slot:cell(title)="data"> <span v-if="!data.item.editable"><img src="../../../src/assets/images/icon/pdf.png" width="32px" height="32px" alt="pdf" class=" mr-2">{{ data.item.policy }}</span>
                  <input type="text" v-model="data.item.policy" v-else class="form-control">
                </template>
                <template v-slot:cell(author)="data"> <span v-if="!data.item.editable">{{ data.item.author }}</span>
                  <input type="text" v-model="data.item.author" v-else class="form-control">
                </template>
                <template v-slot:cell(type)="data"> <span v-if="!data.item.editable">{{ data.item.policy_type }}</span>
                  <input type="text" v-model="data.item.policy_type" v-else class="form-control">
                </template>
                <template v-slot:cell(time)="data"> <span v-if="!data.item.editable">{{ data.item.time }}</span>
                  <input type="text" v-model="data.item.time" v-else class="form-control">
                </template>
                
                <template v-slot:cell(action)="data">
                   <b-button variant=" iq-bg-success mr-1" size="sm" @click="editPolicy(data.item,data.index,$event.target)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="removePolicy(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                   
                   <a :href='data.item.pdf_path' target="_blank"><i class="ri-download-2-line mr-0  "></i></a>
                </template>


      




              </b-table>
              <b-modal id="delete-policy-modal" title="Delete Policy"  hide-footer>
                <p>Are you sure to delete this Policy ? </p>
                   <b-button class="mt-3"  variant="primary" block @click="deletePolicy">Delete</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide('delete-policy-modal')">Close</b-button>
                </b-modal>
              <b-modal :id="policyModal.id" title="Edit Policy" hide-footer>
                 <form>
                      <div class="form-group mb-0">
                        <input type="text" class="form-control" id="exampleInputSearch" placeholder="Author" v-model="policyModal.author">
                      </div>
                      <br/>
                      <div class="form-group mb-0">
                      <b-form-select v-model="policyModal.policy_type" title="Select Parent User" >
                        <b-form-select-option value="hr">Hr</b-form-select-option>
                        <b-form-select-option value="financial">Financial</b-form-select-option>
                        <b-form-select-option value="tech">Tech</b-form-select-option>
                      </b-form-select>
                      </div>
                      <b-button class="mt-3"  variant="primary" block @click="updatePolicy">Update</b-button>
                    <b-button class="mt-3" block @click="$bvModal.hide(policyModal.id)">Close</b-button>
                      
                  </form>
              </b-modal>
                   <div class="mt-3 mt-5">
                    <b-pagination v-model="currentPage" :value="1" :total-rows="policies.length" :per-page="perPage" aria-controls="my-table" />
                  </div>
                </template>
              </iq-card>  
            </tab-content-item>

          </tab-content>
        </b-col>
      </b-row>
    
    </template>
  </iq-card>
</div>

</template>
<script>
import { socialvue } from '../../config/pluginInit'
import Vue from 'vue'
import CKEditor from '../../../node_modules/ckeditor4-vue'
import ckeditor4 from '../../../node_modules/ckeditor4-vue/dist/ckeditor'
import axios from '../../components/axios'
Vue.use( CKEditor );     
export default {
  name: 'Admincp', 
  components: {CKEditor, Vue, ckeditor4 },
  async mounted () {
    socialvue.index()

    var userData = await JSON.parse(localStorage.getItem('user'));


    if(userData.role === 0){
       this.$router.push('/')
    }


    var parent = this
    axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");

    const config = {
        method: "get",
        url: "users",
    };

   await axios(config).then(res => {
        // res.data.events.forEach(element => {
        //     element.img = require('../../../../assets/images/page-img/s4.jpg')
        // })
        // parent.event = res.data.events
        parent.users = res.data.users
    }).catch(err => {
        console.log(err.response);
    })

     const configpost = {
        method: "get",
        url: "/allposts",
    };

   await axios(configpost).then(res => {
       
        parent.posts = res.data.posts
        // parent.users = res.data.users
    }).catch(err => {
        console.log(err.response);
    })

    const configevent = {
        method: "get",
        url: "/allnews",
    };

   await axios(configevent).then(res => {
        // res.data.events.forEach(element => {
        //     element.img = require('../../../../assets/images/page-img/s4.jpg')
        // })
        parent.events = res.data.allNews
        // parent.users = res.data.users
    }).catch(err => {
        console.log(err.response);
    })


    const configpolicy = {
        method: "get",
        url: "/allpolicies",
    };

   await axios(configpolicy).then(res => {
        // res.data.events.forEach(element => {
        //     element.img = require('../../../../assets/images/page-img/s4.jpg')
        // })
        parent.policies = res.data.policies
        // parent.users = res.data.users
    }).catch(err => {
        console.log(err.response);
    })
  },  
  
  methods: {    
   async add () {
      // let obj = this.default()
      // this.rows.push(obj)

      var parent  = this
      const config = {
          method: "post",
          url: "signup",
          data:this.user,
      };


      await axios(config).then(res => {
          parent.users.unshift(res.data.user)
          this.$bvModal.hide('modal1')
          parent.user.name = "";
          parent.user.email = "";
          parent.user.password_confirmation = ""
          parent.user.password = ""
          parent.user.parentId = 0
          parent.user.designation = ""

         
        }).catch(err => {
            if(err.response.data.errors){
              parent.userError.email = err.response.data.errors.email[0]
              parent.userError.name = err.response.data.errors.name[0]
              parent.userError.designation = err.response.data.errors.designation[0]
              parent.userError.password = err.response.data.errors.password[0]
            }
            
        })
    },
    async addEvent () {
      // let obj = this.default()
      // this.rows.push(obj)

      var parent  = this
      const data = new FormData();

       for( var i = 0; i < this.event.image.length; i++ ){
        let media = this.event.image[i];
        data.append('media[' + i + ']', media);
      }

      data.append("media", this.event.image);
      data.append("caption", this.event.caption);
      data.append("description", this.event.description);
      data.append("type", this.event.type);

      const config = {
          method: "post",
          url: "createnews",
          data:data,
          headers: { 'content-type': 'multipart/form-data' }
      };
      await axios(config).then(res => {
          parent.events.unshift(res.data.news)
          this.$bvModal.hide('modal2')
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    async addPolicy () {
      // let obj = this.default()
      // this.rows.push(obj)

      var parent  = this

      const data = new FormData();

      data.append("policy", this.policy.pdfFile);
      data.append("author", this.policy.author);
      data.append("policy_type", this.policy.type);


      const config = {
          method: "post",
          url: "createpolicy",
          data:data,
          headers: { 'content-type': 'multipart/form-data' }
        };
     
      await axios(config).then(res => {
          parent.policies.unshift(res.data.policy)
          this.$bvModal.hide('modal3')
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        username: '',      
        activated: '',
        editable: false
      }
    },
    edit (item,index,button) {
      // item.editable = true
      //  this.infoModal.title = `Row index: ${index}`
      var user_data = JSON.parse(item.user_data)

       this.infoModal.name = item.name
       this.infoModal.email = item.email
       this.infoModal.userId = item.id
        this.infoModal.designation = user_data.designation
       
      //  this.infoModal.password = item.password
       this.infoModal.parentId = item.parentId
        // this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    editEvent (item,index,button) {
      // item.editable = true
      //  this.infoModal.title = `Row index: ${index}`
       this.eventModal.caption = item.caption
       this.eventModal.description = item.description
       this.eventModal.type = item.type
       this.eventModal.eventId = item.id
       this.eventModal.image = item.media
      //  this.infoModal.password = item.password
      //  this.infoModal.parentId = item.parentId
        // this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.eventModal.id, button)
    },
    editPolicy (item,index,button) {
      // item.editable = true
      //  this.infoModal.title = `Row index: ${index}`
       this.policyModal.author = item.author
       this.policyModal.policy_type = item.policy_type
       this.policyModal.policyId = item.id
      //  this.infoModal.password = item.password
      //  this.infoModal.parentId = item.parentId
        // this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.policyModal.id, button)
    },
    viewpost (item,index,button) {
      // item.editable = true
      //  this.infoModal.title = `Row index: ${index}`
       this.postModal.username = item.name
       this.postModal.images = item.images
       this.postModal.caption = item.description
      //  this.infoModal.email = item.email
      //  this.infoModal.userId = item.id
      //  this.infoModal.password = item.password
      //  this.infoModal.parentId = item.parentId
        // this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.postModal.id, button)
    },
    async editUser(){

      var parent  = this
      const config = {
          method: "post",
          url: "edit-user",
          data:this.infoModal,
      };

      await axios(config).then(res => {
          // parent.users.unshift(res.data.user)

          // parent.users.findIndex()
          let index = parent.users.findIndex(
            (user) => user.id === this.infoModal.userId
          );
          this.users[index] = res.data.user;
          this.forceRerender();
          this.$bvModal.hide(this.infoModal.id)

          
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    async updateEvent(){

      var parent  = this
      const config = {
          method: "post",
          url: "edit-event",
          data:this.eventModal,
      };

      await axios(config).then(res => {
          // parent.users.unshift(res.data.user)

          // parent.users.findIndex()
          let index = parent.events.findIndex(
            (event) => event.id === this.eventModal.eventId
          );
          this.events[index] = res.data.event;
          this.forceRerender();
          this.$bvModal.hide(this.eventModal.id)

          
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    async updatePolicy(){
      var parent  = this
      const config = {
          method: "post",
          url: "updatepolicy",
          data:this.policyModal,
      };

      await axios(config).then(res => {
          // parent.users.unshift(res.data.user)

          // parent.users.findIndex()
          let index = parent.policies.findIndex(
            (policy) => policy.id === this.policyModal.policyId
          );
          this.policies[index] = res.data.policy;
          this.forceRerender();
          this.$bvModal.hide(this.policyModal.id)

         
        }).catch(err => {
            console.log(err.response);
        })
    },
    async deleteUser(){

      var parent  = this
      const config = {
          method: "post",
          url: "delete-user",
          data:{
            userId:this.deleteId
          },
      };


       await axios(config).then(res => {
         
          let index = parent.users.findIndex(
            (user) => user.id === this.deleteId
          );
          this.users.splice(index, 1);
          // this.users[index] = res.data.user;
          this.forceRerender();
          this.$bvModal.hide('delete-modal')
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    async deletePost(){

      var parent = this;
       const config = {
          method: "post",
          url: "deletepost",
          data:{
            id:this.deletepostId,
          },
      };
        axios(config).then(res => {
          // parent.posts = parent.posts.filter(post =>  post.id !== this.deletepostId  )
          let index = parent.posts.findIndex(
            (post) => post.id === this.deletepostId
          );
          this.posts.splice(index, 1);
          this.forceRerender();
          this.$bvModal.hide('delete-post-modal')
          
        }).catch(err => {
            console.log(err);
        })
    },
     async deleteEvent(){

      var parent  = this
      const config = {
          method: "post",
          url: "delete-event",
          data:{
            eventId:this.deleteEventId
          },
      };


       await axios(config).then(res => {
          // parent.users.unshift(res.data.user)

          // parent.users.findIndex()
          let index = parent.events.findIndex(
            (event) => event.id === this.deleteEventId
          );
          this.events.splice(index, 1);
          // this.users[index] = res.data.user;
          this.forceRerender();
          this.$bvModal.hide('delete-event-modal')
         
        }).catch(err => {
            console.log(err.response);
        })
    },
     async deletePolicy(){

      var parent  = this
      const config = {
          method: "post",
          url: "deletepolicy",
          data:{
            id:this.deletePolicyId
          },
      };


       await axios(config).then(res => {
          // parent.users.unshift(res.data.user)

          // parent.users.findIndex()
          let index = parent.policies.findIndex(
            (policy) => policy.id === this.deletePolicyId
          );
          this.policies.splice(index, 1);
          // this.users[index] = res.data.user;
          this.forceRerender();
          this.$bvModal.hide('delete-policy-modal')
         
        }).catch(err => {
            console.log(err.response);
        })
    },
    forceRerender () {
        // remove the my-component component from the DOM
        this.renderComponent = false;

        this.$nextTick (() => {
          // add my-component component in DOM
          this.renderComponent = true;
        });
      },
    resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      resetpostModal() {
        this.event.caption = ''
       this.event.description = ''
        this.event.type = 'event'
      },
       resetpolicyModal() {
        this.policy.caption = ''
       this.policy.description = ''
        this.policy.type = 'hr'
      },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      this.$root.$emit('bv::show::modal', 'delete-modal')
      this.deleteId = item.id
      // let index = this.rows.indexOf(item)
      // this.rows.splice(index, 1)
    },
     removePost (item) {
      this.$root.$emit('bv::show::modal', 'delete-post-modal')
      this.deletepostId = item.id
      // let index = this.rows.indexOf(item)
      // this.rows.splice(index, 1)
    },
    removeEvent (item) {
      this.$root.$emit('bv::show::modal', 'delete-event-modal')
      this.deleteEventId = item.id
      // let index = this.rows.indexOf(item)
      // this.rows.splice(index, 1)
    },
    removePolicy (item) {
      this.$root.$emit('bv::show::modal', 'delete-policy-modal')
      this.deletePolicyId = item.id
      // let index = this.rows.indexOf(item)
      // this.rows.splice(index, 1)
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
  },
  data () {
    return {
      users:[],
      posts:[],
      events:[],
      policies:[],
      event:{
        caption:'',
        description:'',
        type:'event',
        image:[]
      },
      policy:{
        author:'',
        type:'hr',
        pdfFile:''
      },
      user:{
        name:'',
        email:'',
        password:"",
        password_confirmation:"",
        parentId:0,
        designation:'',
      },
      userError:{
        name:'',
        email:'',
        designation:'',
        password:'',
      },
      perPage: 20,
      currentPage: 1,
      filter: null,
      userOption:[],
      selected: null,
      infoModal: {
          id: 'info-modal',
          // title: '',
          userId:'',
          // content: '',
          name:'',
          email:'',
          // password:'',
          parentId:''
        },
        postModal: {
          id: 'post-modal',
          images:[],
          caption:'',
          // title: '',
          username:'',
          // content: '',
          // name:'',
          // email:'',
          // password:'',
          // parentId:''
        },
        eventModal: {
          id: 'event-modal',
          caption:'',
          // title: '',
          description:'',
          type:'',
          eventId:'',
          image:[],
          // content: '',
          // name:'',
          // email:'',
          // password:'',
          // parentId:''
        },
        policyModal: {
          id: 'policy-modal',
          author:'',
          // title: '',
          policy_type:'',
          policyId:'',
          // content: '',
          // name:'',
          // email:'',
          // password:'',
          // parentId:''
        },
        renderComponent: true,
        deleteId:'',
        deletepostId:'',
        deleteEventId:'',
        deletePolicyId:'',
      columns: [
        { label: 'ID', key: 'id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Email', key: 'email', class: 'text-left' },       
         { label: 'Action', key: 'action', class: 'text-center' }
      ],
      postcolumns: [
        { label: 'ID', key: 'id', class: 'text-left' },
        { label: 'user name', key: 'username', class: 'text-left' },
        { label: 'Post Type', key: 'posttype', class: 'text-left' },
        { label: 'Time', key: 'time', class: 'text-left' },
         { label: 'Action', key: 'action', class: 'text-center' }
      ],
      eventscolumns: [
        { label: 'ID', key: 'id', class: 'text-left' },
        { label: 'title', key: 'title', class: 'text-left' },
        { label: 'Type', key: 'type', class: 'text-left' },
        { label: 'Time', key: 'time', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      policycolumns:[
        { label: 'ID', key: 'id', class: 'text-left' },
        { label: 'title', key: 'title', class: 'text-left' },
        { label: 'Author', key: 'author', class: 'text-left' },
        { label: 'Type', key: 'type', class: 'text-left' },
        { label: 'Time', key: 'time', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      rows: [
        {
          id: 1,
            name: 'John',
            username: 'john',            
            activated: 'yes',
            editable: false
        },
        {
          id: 2,
          name: 'John',
            username: 'john',           
            activated: 'yes',
            editable: false
        },
        {
          id: 3,
           name: 'John',
            username: 'john',            
            activated: 'yes',
            editable: false
        },
        
      ]
    }
  }
};

</script>
<style>
.navbar-expand-md .navbar-nav .dropdown-menu {
    position: static;
}	
		
</style>